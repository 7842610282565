// import causes issues for some reason. and for this, we may lose vue tree-shakability. This is a framework so we are ok with importing all of Vue
const { watchEffect, nextTick } = require("vue");
import _o from "lodash/object";

/**
 * Custom async import, that will look for overrides, loading the override if possible
 * @param route
 * @returns {Promise<*>}
 */
const customImport = async function (route) {
  let cleanRoute = route;
  let overrideKey = `@/overrides/client/${cleanRoute}`;
  if (route.indexOf("@/") === 0 || route.indexOf("./") === 0) {
    cleanRoute = route.substring(2);
  }

  try {
    return await import(/* webpackChunkName: "[request]" */ `${overrideKey}`);
  } catch (e) {
    console.log(`@/client/${cleanRoute}`);
    return await import(
      /* webpackChunkName: "[request]" */ `@/client/${cleanRoute}`
    );
  }
};

const customImportWithAppSource = async function (route) {
  let appName = process.env.VUE_APP_APPLICATION_NAME;
  let cleanRoute = route;

  if (route.indexOf("@/") === 0 || route.indexOf("./") === 0) {
    cleanRoute = route.substring(2);
  }

  let cleanAppRoute = `applications/${appName}/${cleanRoute}`;
  let overrideKey = `@/overrides/client/${cleanRoute}`;
  let appOverrideKey = `@/overrides/client/applications/${appName}/${cleanRoute}`;

  try {
    return await import(
      /* webpackChunkName: "[request]" */ `${appOverrideKey}`
    );
  } catch (e) {}

  try {
    return await import(
      /* webpackChunkName: "[request]" */ `@/client/${cleanAppRoute}`
    );
  } catch (e) {}

  try {
    // NOTICE this is not tested
    return await import(/* webpackChunkName: "[request]" */ `${overrideKey}`);
  } catch (e) {}

  // import core file - if this fails, than we allow the exception to be thrown
  return await import(
    /* webpackChunkName: "[request]" */ `@/client/${cleanRoute}`
  );
};

function ucFirst(string) {
  if (typeof string !== "string") {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function upperFirst(string) {
  if (typeof string !== "string") {
    return string;
  }
  return ucFirst(string);
}

function lcFirst(string) {
  if (typeof string !== "string") {
    return string;
  }
  return string.charAt(0).toLowerCase() + string.slice(1);
}

function lowerFirst(string) {
  if (typeof string !== "string") {
    return string;
  }
  return lcFirst(string);
}

function titleCase(string) {
  "use strict";
  if (typeof string !== "string") {
    return string;
  }
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;

  return string
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ":" &&
        array[index + 1] !== ":" &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== "-" ||
          (array[index - 1] === "-" && array[index + 1] === "-"))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substring(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ":" && array[index + 2] !== "") {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join("");
}

function getUrlSafeString(input) {
  if (typeof input !== "string") {
    return input;
  }
  return input.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_");
}

const debugFunction = (message, level, data) => {
  if (!config.debug) {
    return;
  }

  level = parseInt(level);

  if (typeof console === "undefined") {
    return;
  }

  if (typeof data === "undefined") {
    data = "[data was not provided by caller]";
  }

  let levelColor;
  let wrapperColor;

  switch (level) {
    case 3:
    case "3":
      levelColor = "#f50a41";
      wrapperColor = "#f50a41";
      break;
    case 2:
    case "2":
      levelColor = "#d97409";
      wrapperColor = "#d97409";
      break;
    case 1:
    case "1":
    default:
      levelColor = "white";
      wrapperColor = "white";
      break;
  }

  console.log(
    "%c/============================================================",
    "color: " + wrapperColor + ";"
  );
  console.log(
    "%c|%c Error [%cLevel " + level + "%c]:",
    "color: " + wrapperColor + ";",
    "color:white;",
    "color: " + levelColor + ";",
    "color:white;"
  );
  console.log(
    "%c|%c Description: %c" + message,
    "color: " + wrapperColor + ";",
    "color:white;",
    "color:white;;"
  );
  console.log(
    "%c| %cdata: ",
    "color: " + wrapperColor + ";",
    "color:white; ",
    data
  );
  console.log(
    "%c\\============================================================",
    "color: " + wrapperColor + ";"
  );

  if (level > 2) {
    console.log("Throwing Exception for error with level 3 and above");
    throw new Error(message);
  }
};

const warn = (message, data) => {
  return debugFunction(message, 2, data);
};

function requireAsset(path) {
  let result = false,
    context;
  let appName = process.env.VUE_APP_APPLICATION_NAME;

  // check for application asset override
  // context = require.context('@/', true, /overrides\/client\/applications\/\.\/.*\/assets\/static.*/);
  //
  //  context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/assets\/static.*$/);
  context = require.context(
    "@/",
    true,
    /overrides\/client\/applications\/.*\/assets\/static\/.*/
  );

  context.keys().forEach((key) => {
    let target = `./${appName}/assets/static/` + path;
    let matchKey = key.replace("overrides/client/applications/", "");

    // filter only the modules for out application
    if (target === matchKey) {
      result = context(key);
      return true;
    }
  });

  if (result) {
    if (result?.default) {
      return result.default;
    } else {
      return result;
    }
  }

  // check for application asset
  context = require.context(
    "@/client/applications/",
    true,
    /^\.\/.*\/assets\/static.*$/
  );
  context.keys().forEach((key) => {
    let target = `./${appName}/assets/static/` + path;

    // filter only the modules for out application
    if (target === key) {
      result = context(key);
      return true;
    }
  });

  if (result) {
    if (result?.default) {
      return result.default;
    } else {
      return result;
    }
  }

  // check for core override;
  //  context = require.context('@/overrides/client/assets/static/', true, /\.*/);
  context = require.context(
    "@/",
    true,
    /\/overrides\/client\/assets\/static\.*/
  );

  context.keys().forEach((key) => {
    let compare = key.replace("./overrides/client/assets/static/", "");

    if (compare === path) {
      result = context(key);
      return true;
    }
  });

  if (result) {
    if (result?.default) {
      return result.default;
    } else {
      return result;
    }
  }

  // resort to core file
  try {
    return require(`@/client/assets/static/${path}`);
  } catch (e) {
    debug("Can not find asset, probably missing file. check your path", 2, {
      exception: e,
      message: e.message,
      path: path,
    });
    return null;
  }
}

let unique = 0;

const getRandomInt = (max = 10) => {
  return Math.floor(Math.random() * Math.floor(max));
};

const getUniqueNumber = () => {
  unique = unique + 1;

  if (!isSSR()) {
    unique = unique + 1;
    return unique;
  }

  // on SSR we need to be more careful with this - overtime it will overflow, and multiple apps touch it
  // also make sure we dont overflow on INT
  if (Date.now() + unique > Number.MAX_SAFE_INTEGER - 10) {
    unique = 0;
  }

  unique = unique + 1;

  return Date.now() + unique;
};

const setClientTimeout = (cb, delay) => {
  if (isClient()) {
    return setTimeout(cb, delay);
  } else {
    cb();
    return 0;
  }
};

const setClientInterval = (cb, delay) => {
  if (isClient()) {
    // this is SSR safe, we test for it right here
    return setInterval(cb, delay);
  } else {
    return cb();
  }
};

const isSSR = () => {
  return typeof window !== "object";
};

const isClient = () => {
  return !isSSR();
};

const wait = async function (time) {
  return new Promise((resolve) => {
    // this is SSR safe, as we are waiting for the timeout
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

/**
 * Return a promise that fulfills when a condition is met. Doesnt use reactivity
 * @param handler - function whose return value ( == bool) indicated if the condition is met
 * @param pollRate - interval between checking the condition (ms)
 * @returns {Promise<*>}
 */

const waitUntil = async function (handler, pollRate = 50) {
  let interval;

  return new Promise((fulfil, reject, immediate = true) => {
    let checkCondition = async () => {
      return await handler();
    };

    // check immediately if needed
    if (immediate) {
      checkCondition().then((res) => {
        if (res) {
          clearInterval(interval);
          fulfil();
        }
      });
    }

    // start polling
    // this is SSR safe becuase we wait forever and clean up after ourselves.
    interval = setInterval(async () => {
      if (await checkCondition()) {
        clearInterval(interval);
        fulfil(true);
      }
    }, pollRate);
  });
};

const waitWithSpinner = async function (store, text, duration = 2000) {
  store.commit("ui/showGlobalSpinner", text);
  await wait(duration);
  store.commit("ui/hideGlobalSpinner");
  return true;
};

const isObject = (val) => val && typeof val === "object";

const hasProperty = (target, prop) =>
  isObject(target) && Object.keys(target).includes(prop);

const hasAnyProperty = (target, props) => {
  if (!isObject(target)) {
    return false;
  }

  return props.some((prop) => target.hasOwnProperty(prop));
};

const hasOwnProperty = (target, prop) =>
  isObject(target) && Object.keys(target).includes(prop);

const isMobile = () => {
  if (isSSR()) {
    return false;
  }
  return window.outerWidth <= 981;
};

const waitForUser = async (store) => {
  if (isClient()) {
    return await new Promise((resolve) => {
      watchEffect(() => {
        let resolveTimeout = setTimeout(() => {
          clearTimeout(resolveTimeout);
          resolve();
        }, 5000);
        if (store.getters["user/initialFetchComplete"]) {
          clearTimeout(resolveTimeout);
          resolve();
        }
      });
    });
  }

  // SSR - we must poll, no reactivity
  return await new Promise((resolve) => {
    let checkInterval;
    let resolveTimeout;
    // poll the user every 0.1 second. if it is fetched - clear interval and timeout
    checkInterval = setInterval(() => {
      if (store.getters["user/initialFetchComplete"]) {
        clearInterval(checkInterval);
        clearTimeout(resolveTimeout);
        resolve();
      }
    }, 100);

    // do not wait too long
    resolveTimeout = setTimeout(() => {
      clearInterval(checkInterval);
      clearTimeout(resolveTimeout);
      resolve();
    }, 2000);
  });
};

const safeLog = (arg) => {
  if (config.debug) {
    console.log(arg);
  }
};

const mergeObjects = (arg1, arg2) => {
  return _o.merge(arg1, arg2);
};

const getRandomString = (length = 10, pool = false) => {
  if (!pool) {
    pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  }
  let result = "";

  for (let i = 0; i < length; i++) {
    result += pool.charAt(Math.floor(Math.random() * length));
  }
  return result;
};

const getRandomDigits = (length = 10) => {
  return getRandomString(length, "0123456789");
};

const debounce = (cb, delay, options = { returnType: "functionOnly" }) => {
  let timeout = false;

  let debounce = () => {
    clearTimeout(timeout);
    timeout = setTimeout(cb, delay);
  };

  let clear = () => {
    clearTimeout(timeout);
  };

  if (options && options.returnType === "functionOnly") {
    return debounce;
  } else {
    return { run: debounce, debounce, clear };
  }
};

const promiseWithTimeLimit = (promise, maxExecutionTime) => {
  let maxTimePromise = new Promise((fulfil, reject) => {
    setTimeout(
      () => reject(new Error("time limit exceeded")),
      maxExecutionTime
    );
  });

  return Promise.race([promise, maxTimePromise]);
};

const awaitNextTick = async (cb) => {
  return await new Promise((resolve) => {
    nextTick(() => {
      if (cb) {
        cb();
      }
      resolve();
    });
  });
};

const inTwoTicks = (cb) => {
  nextTick(() => {
    nextTick(cb).then(() => {});
  }).then(() => {});
};

const nlToBr = (text) => {
  if (typeof text !== "string") {
    return text;
  }
  return text.replaceAll("\n", "<br />");
};

const isNonEmptyString = (input) => {
  return typeof input === "string" && input !== "";
};

const isNonEmptyObject = (input) => {
  if (!input) {
    return false;
  }

  if (typeof input !== "object") {
    return false;
  }

  return Object.keys(input).length > 0;
};
export {
  waitForUser,
  isObject,
  isNonEmptyString,
  isNonEmptyObject,
  hasProperty,
  hasOwnProperty,
  hasAnyProperty,
  customImport,
  customImportWithAppSource,
  waitUntil,
  ucFirst,
  lcFirst,
  upperFirst,
  lowerFirst,
  titleCase,
  requireAsset,
  requireAsset as getDynamicAssetUrl,
  requireAsset as getDynamicAsset,
  requireAsset as getAssetUrl,
  requireAsset as getAsset,
  getUniqueNumber,
  isSSR,
  isClient,
  setClientTimeout,
  setClientInterval,
  wait,
  waitWithSpinner,
  getUrlSafeString,
  isMobile,
  warn,
  safeLog,
  mergeObjects,
  debugFunction as debug,
  getRandomString,
  getRandomInt,
  getRandomDigits,
  debounce,
  promiseWithTimeLimit,
  inTwoTicks,
  awaitNextTick,
  awaitNextTick as nextTick,
  nlToBr,
  nlToBr as nl2Br,
};
