export default async ({ asyncOps, store }) => {
  let navFactory;
  let navItems;
  try {
    navFactory =
      await require("@/client/assets/static/core/nav/userDashboardNavFactory.js");
    navItems = await navFactory.default({ asyncOps, store });
  } catch (e) {
    return {};
  }

  let isVendor = !!store.getters["user/profile"]?.Vendor?.id;

  let result = {
    ...navItems,
  };

  let contractsItem =  {
    label: "artists.userDashboard.contracts.navLabel",
    icon: "paragraph",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "user-dashboard-contracts",
        },
      },
    },
  };


  if (isVendor) {
    result.vendorItems.children["contracts"] = {
      label: "artists.userDashboard.contracts.navLabel",
      icon: "paragraph",
      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "user-dashboard-vendor-contracts",
          },
        },
      },
    };
    result.userItems.contracts = contractsItem;
  } else { // normal user
    result.contracts = contractsItem;
  }


  return result;
};
